import React from "react";
import {
  MdDownload,
  MdOutline10K,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Link } from "react-scroll";

const EpreuveE4 = () => {
  const links = [
    {
      id: 1,
      href: "/TableauDeSynthese.pdf",
      name: "Tableau de synthèse des réalisations professionelles",
    },
    // {
    //   id: 2,
    //   href: "/CV_YanisBenlalam.pdf",
    //   name: "Attestation de stage",
    // },
  ];
  return (
    <div
      name="epreuve E4"
      className="bg-gradient-to-b sm:pb-60 from-black to-gray-800 w-full text-white md:h-fit"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Epreuve E4
          </p>
          <div className="pt-2">
            <p className="text-2xl  font-bold inline border-b-4 border-gray-500">
              Présentation
            </p>
          </div>
          <div>
            <p className="text-xl mt-10">
              Cette épreuve vise à évaluer chez la personne candidate
              l’acquisition des compétences décrites dans le bloc de compétences
              « Support et mise à disposition de services informatiques ».
              <br></br>
              <br></br>
              L’épreuve comporte deux phases consécutives. Pendant 10 minutes
              (maximum) la personne candidate présente son parcours de
              professionnalisation et justifie les modalités d’acquisition des
              compétences du bloc « Support et mise à disposition de services
              informatiques ». S’en suit 30 minutes d’échange avec le jury qui
              permettent, d’une part, d’expliciter et d’apprécier le parcours de
              professionnalisation et, d’autre part, d’approfondir une ou
              plusieurs réalisations décrites dans le dossier numérique.
              L’entretien permet d’apprécier la capacité de la personne
              candidate à mobiliser les compétences visées, sa capacité à rendre
              compte d’un travail réalisé au sein d’une équipe projet en mettant
              clairement en évidence sa contribution personnelle.
            </p>
          </div>
          <div className="pt-2">
            <p className="text-2xl  font-bold inline border-b-4 border-gray-500">
              Documents Annexes
            </p>

            <div>
              {links.map((link) => (
                <a
                  key={link.id}
                  href={link.href}
                  target="blank"
                  className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r  from-purple-400 to-purple-600 cursor-pointer hover:scale-110 duration-300"
                >
                  {link.name}
                  <span className="">
                    <MdDownload size={25} className="ml-1" />
                  </span>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0"></div>
      </div>
    </div>
  );
};

export default EpreuveE4;
