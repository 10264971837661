import React from "react";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-fit bg-gradient-to-b from-black to-gray-800 p-4 text-white"
    >
      <div className=" flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Me contacter
          </p>
          <p className="py-6">
            Soumettre le formulaire ci-dessous pour me contacter
          </p>
        </div>

        <div className="flex justify-center items-center ">
          <form
            action="https://getform.io/f/17787a2d-94a8-4455-a3af-6cecfa1db01f"
            className="flex flex-col w-full md:w-1/2"
            method="POST"
          >
            <input
              type="text"
              name="name"
              placeholder="Saisir votre nom"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <input
              type="text"
              name="email"
              placeholder="Saisir votre mail"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />

            <textarea
              name="messsage"
              placeholder="Saisir votre message"
              rows={10}
              className="p-2 bg-transparent border-2 text-white rounded-md focus:outline-none"
            ></textarea>
            <button className="text-white bg-gradient-to-b from-purple-400 to-purple-600 px-6 py-3 my-8 mx-auto flex items-center rounded-md cursor-pointer hover:scale-110 duration-300">
              Me contacter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
