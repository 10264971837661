import React from "react";

import HeroImage from "../assets/heroImage.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div
      name="home"
      className="h-fit w-full pt-40 sm:pb-60 md:pt-60  bg-gradient-to-b from-black via-black to-gray-800 text-white"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className=" flex flex-col justify-center h-full">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            Yanis, apprenti développeur Futur étudiant 2024-2025 en Licence 3
            MIAGE en alternance à Nantes
          </h2>
          <p className="text-gray-500 py-4 max-w-md text-justify ">
            Je m'appelle Yanis, ayant terminé mon BTS SIO (Service Informatique
            Aux Organisations) spécialité SLAM (développement d'applications) je
            poursuis mes études en Licence 3 MIAGE en alternance au sein de la
            faculté des sciences de Nantes, afin de conclure sur un Master MIAGE
            en alternance également.
          </p>
          <b>
            Actuellement en recherche d'une alternance pour 2024-2025 dans le
            domaine du développement informatique
          </b>

          <div>
            <Link
              to="a propos"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r  from-purple-400 to-purple-600 cursor-pointer"
            >
              En apprendre plus
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>
        {/* <div>
          <img
            src={HeroImage}
            alt="mon profil"
            className="rounded-2xl mx-auto w-2/3 md:w-full"
          ></img>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
