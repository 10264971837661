import React from "react";

const Veille = () => {
  return (
    <div
      name="veille"
      className="bg-gradient-to-b sm:pb-60 from-gray-800 to-black w-full text-white md:h-fit"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Veille technologique
          </p>
          <div className="pt-2">
            <p className="text-2xl  font-bold inline border-b-4 border-gray-500">
              Présentation de la veille
            </p>
          </div>
          <div>
            <p className="text-xl mt-10">
              Dans le cadre du BTS SIO, la mise en place d’une veille
              technologique est imposée. La veille technologique consiste à
              s’informer régulièrement sur les évolutions technologiques et
              numériques et surtout sur leurs mise à disposition commerciale.
              Pour ma part, j’organise ma veille technologique sur un sujet qui
              m’intéresse et qui représente l'avenir : l'intelligence
              artificielle.
            </p>
          </div>
          <div className="pt-2">
            <p className="text-2xl  font-bold inline border-b-4 border-gray-500">
              Outils utilisés
            </p>
          </div>
          <div>
            <p className="text-xl mt-10">
              Pour mener à bien ma veille technologique je me suis servi de
              divers outils, notamment des newsletters telles que Feedly, un
              agrégateur de flux RSS, TLDR et AI Reverie qui me fournissent
              directement par mail des petits résumé des nouveautés dans le
              monde de l'IA chaque jour.
              <br></br>
              Hormis ces outils, je consulte également des sites spécialisés
              tels que Openai, Hitek, Frandroid, ...
              <br></br>
              Et enfin, je consomme également du contenu vidéo, à travers des
              chaines YouTube comme Underscore_, Micode, Nowtech, Leo
              Techmaker...
            </p>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0"></div>
      </div>
    </div>
  );
};

export default Veille;
