import React from "react";
import { Link } from "react-scroll";

const About = () => {
  return (
    <div
      name="a propos"
      className="w-full h-fit sm:pb-60 bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500  ">
            A propos
          </p>
        </div>
        <p className="text-xl mt-20">
          Intérréssé par le domaine du développement informatique dès le collège
          en écrivant mes premières lignes de Python, c'est tout logiquement que
          j'ai suivi un cursus scolaire dans cette optique de pouvoir en
          découvrir plus et de m'investir professionnellement dans ce domaine.
        </p>
        <br />

        <p className="text-xl">
          Ma formation actuelle m'a permis de grandement approfondir mes
          compétences en programmation WEB/métier et gestion de projet à travers
          des TP, ainsi que des projets. En travaillant avec des technologies
          dont vous pouvez retrouver les détails.{" "}
          <Link to="technologies" smooth duration={500}>
            <b>
              {" "}
              <u>ici</u>
            </b>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default About;
