import React, { useState } from "react";
import reactMovies from "../assets/portfolio/reactMovies.jpg";
import mealApp from "../assets/portfolio/mealApp.jpg";
import hackatInnov from "../assets/portfolio/hackatInnov.jpg";
import hackatWeb from "../assets/portfolio/hackatWeb.JPG";
import hackatWebViewHack from "../assets/projectsPics/hackatweb/viewHack.png";
import hackatWebRegister from "../assets/projectsPics/hackatweb/register.png";
import hackatWebRegistration from "../assets/projectsPics/hackatweb/registration.png";
import hackatOrgaPicTest from "../assets/projectsPics/hackatorga/test.png";
import hackatOrgaPicMain from "../assets/projectsPics/hackatorga/main.png";
import hackatOrgaPicOrga from "../assets/projectsPics/hackatorga/orga.JPG";
import portfolio from "../assets/projectsPics/portfolio/portfolioMain.JPG";
import portfolioDeploy from "../assets/projectsPics/portfolio/deploy.JPG";
import webconcepterDevis from "../assets/projectsPics/webconcepterDevis/devis.JPG";
import WebconcepterCodeEnvoie from "../assets/projectsPics/webconcepterDevis/codeenvoie.JPG";
import WebconcepterCodeGoogle from "../assets/projectsPics/webconcepterDevis/codegoogle.JPG";
import WebconcepterCodeApi from "../assets/projectsPics/webconcepterDevis/codeapi.JPG";
import muslimStory from "../assets/projectsPics/muslimstory/main2.jpg";
import muslimStoryMain from "../assets/projectsPics/muslimstory/main.jpg";
import muslimStoryDeploy from "../assets/projectsPics/muslimstory/deploy.JPG";
import muslimStorySiteMain from "../assets/projectsPics/muslimstorysite/main.JPG";
import movieAppMain from "../assets/projectsPics/movieApp/main.JPG";
import movieAppLikes from "../assets/projectsPics/movieApp/liked.JPG";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Portfolio = () => {
  const projects = [
    {
      id: 1,
      src: reactMovies,
      title: "React Movies App",
      images: [
        {
          image: movieAppMain,
          text: "Page d'accueil",
        },
        {
          image: movieAppLikes,
          text: "Page des likes",
        },
      ],
      details: (
        <div>
          <p>
            Ce projet React est une application web de film qui permet aux
            utilisateurs de rechercher des films, afficher les détails et
            éventuellement les ajouter à leur likes.
          </p>
          <p className="text-2xl font-bold"> Technos utilisées </p>
          <ul className="list-disc ml-8">
            <li>ReactJS</li>
            <li>API</li>
          </ul>
        </div>
      ),
    },
    // {
    //   id: 2,
    //   src: mealApp,
    //   title: "React Meal App",
    //   details: (
    //     <div>
    //       <p>
    //         This React project is a meal planning and recipe app that helps
    //         users find and manage recipes, create meal plans, and generate
    //         grocery lists.
    //       </p>
    //       <ul>
    //         <li>Built using React, JavaScript, and APIs</li>
    //         <li>
    //           Features recipe search, meal planning, and grocery list generation
    //         </li>
    //       </ul>
    //       <a href="https://github.com/your-username/react-meal-app">
    //         View Code on GitHub
    //       </a>
    //     </div>
    //   ),
    // },
    {
      id: 3,
      src: hackatInnov,
      title:
        "Projet AP : Application Java de gestion de la configuration de Hackathons",
      images: [
        {
          image: hackatOrgaPicMain,
          text: "Page d'accueil",
        },
        {
          image: hackatOrgaPicTest,
          text: "Extrait de code des tests d'intégration",
        },
        {
          image: hackatOrgaPicOrga,
          text: "Organisation du projet",
        },
      ],

      details: (
        <div>
          <p className="text-2xl font-bold"> Contexte </p>
          <p>
            En deuxième année nous avons travaillé tout au long de l'année sur
            un contexte Hackat'Innov, qui nous a amené à travailler sur 3
            projets différents dont Hackat'Orga qui a été notre premier projet
            sur ce contexte.
          </p>
          <p>
            Cette application avait pour objectif de permettre de gérer les
            étapes du processus visant à configurer le hackathon, qui implémente
            donc la création, modification et suppression des Hackathons.
          </p>

          <p>Les besoins étaient exprimés par le client (nos professeurs )</p>
          <p className="text-2xl font-bold"> Technos utilisées </p>
          <ul className="list-disc ml-8">
            <li>Java</li>
            <li>Maven</li>
            <li>JDBC</li>
          </ul>

          <p className="text-2xl font-bold"> Compétences mises en oeuvre </p>
          <ul className="list-disc ml-8">
            <li>
              Gérer le patrimoine informatique{" "}
              <ul className="list-disc ml-8">
                <li>
                  Respect des normes et standards adoptés par le prestataire
                  informatique en travaillant avec un environnement de
                  développement mis en place par ce dernier.
                </li>
                <li>
                  {" "}
                  Gestion des sauvegardes avec l'utilisation de Framagit pour le
                  versionning de l'application.
                </li>
              </ul>
            </li>
            <li>
              Travailler en mode projet{" "}
              <ul className="list-disc ml-8">
                {" "}
                <li>
                  Utilisation de la fonctionnalité d'attribution des tâches de
                  Framagit pour la gestion de projet (planification et
                  attributions des tâches)
                </li>
                <li>Analyse des objectifs à l'aide d'un cahier des charges</li>
              </ul>
            </li>
            <li>
              Mettre à disposition des utilisateurs un service informatique
              <ul className="list-disc ml-8">
                <li>
                  {" "}
                  Réalisations de tests d'intégration afin d'assurer un bon
                  fonctionnement et suivi de l'application
                </li>
              </ul>
            </li>{" "}
            <li>
              Répondre aux incidents et aux demandes d’assistance et d’évolution
              <ul className="list-disc ml-8">
                <li> Traiter des demandes concernant les applications</li>
              </ul>
            </li>
          </ul>
          <p></p>
        </div>
      ),
    },
    {
      id: 4,
      src: hackatWeb,
      title:
        "Projet AP : Application Symfony de consultation et inscription à des Hackathons",
      images: [
        {
          image: hackatWebViewHack,
          text: "Affichage de la liste des Hackathons",
        },
        {
          image: hackatWebRegister,
          text: "Enregistrement d'un utilisateur",
        },
        {
          image: hackatWebRegistration,
          text: "Détails et inscription à un Hackathon",
        },
      ],

      details: (
        <div>
          <p className="text-2xl font-bold"> Contexte </p>
          <p>
            En deuxième année nous avons travaillé tout au long de l'année sur
            un contexte Hackat'Innov, qui nous a amené à travailler sur 3
            projets différents dont Hackat'Web qui a été notre deuxième projet
            sur ce contexte.
          </p>
          <p>
            Cette application avait pour objectif de permettre de fournir une
            plateforme publique pour les différents utilisateurs, pour consulter
            les Hackathons, de pouvoir s'inscrire et autres fonctionnalités en
            fonction du rôle et privilèges de l'utilisateur.
          </p>

          <p>Les besoins étaient exprimés par le client (nos professeurs )</p>
          <p className="text-2xl font-bold"> Technos utilisées </p>
          <ul className="list-disc ml-8">
            <li>Symfony </li>
            <li>PostgreSQL</li>
            <li>PHPUnit</li>
          </ul>

          <p className="text-2xl font-bold"> Compétences mises en oeuvre </p>
          <ul className="list-disc ml-8">
            <li>
              Gérer le patrimoine informatique{" "}
              <ul className="list-disc ml-8">
                <li>
                  Respect des normes et standards adoptés par le prestataire
                  informatique en travaillant avec un environnement de
                  développement mis en place par ce dernier.
                </li>
                <li>
                  {" "}
                  Gestion des sauvegardes avec l'utilisation de Framagit pour le
                  versionning de l'application.
                </li>
              </ul>
            </li>
            <li>
              Travailler en mode projet{" "}
              <ul className="list-disc ml-8">
                {" "}
                <li>
                  Utilisation de la fonctionnalité d'attribution des tâches de
                  Framagit pour la gestion de projet (planification et
                  attributions des tâches)
                </li>
                <li>Analyse des objectifs à l'aide d'un cahier des charges</li>
              </ul>
            </li>
            <li>
              Mettre à disposition des utilisateurs un service informatique
              <ul className="list-disc ml-8">
                <li>
                  {" "}
                  Réalisations de tests d'intégration afin d'assurer un bon
                  fonctionnement et suivi de l'application
                </li>
              </ul>
            </li>
          </ul>
          <p></p>
        </div>
      ),
    },
    {
      id: 5,
      src: portfolio,
      title: "Réalisation personnelle : Portfolio",
      images: [
        {
          image: portfolioDeploy,
          text: "Déploiement de mon portfolio en ligne avec le service d'hébergement Netlify",
        },
        {
          image: portfolio,
          text: "Page d'accueil de mon portfolio",
        },
      ],

      details: (
        <div>
          <p className="text-2xl font-bold"> Contexte </p>
          <p>
            Dans le cadre de mon BTS SIO j'ai dû développer un portfolio visant
            à exposer mon profil et mes compétences.
          </p>
          <p>
            Mon portfolio a été développé sur mesure par moi même, et est
            régulièrement mis à jour et amélioré. <br></br>Il m'a permit, et
            continue, de me faire développer mes compétences en développement
            avec framework React, que j'ai appris seul mais aussi de
            l'utilisation de Tailwind CSS pour le style.
          </p>
          <p className="text-2xl font-bold"> Technos utilisées </p>
          <ul className="list-disc ml-8">
            <li>ReactJS</li>
            <li>Tailwind</li>
            <li>JavaScript</li>
          </ul>

          <p className="text-2xl font-bold"> Compétences mises en oeuvre </p>
          <ul className="list-disc ml-8">
            <li>
              Développer la présence en ligne de l’organisation
              <ul className="list-disc ml-8"></ul>
            </li>
          </ul>
          <ul className="list-disc ml-8">
            <li>
              Organiser son développement professionnel
              <ul className="list-disc ml-8">
                <li>Gérer son identité professionnelle</li>
              </ul>
            </li>
          </ul>
          <p></p>
        </div>
      ),
    },
    {
      id: 6,
      src: webconcepterDevis,
      title: "Stage : Formulaire de devis et référencement SEO",
      images: [
        {
          image: webconcepterDevis,
          text: "Page de devis",
        },
        {
          image: WebconcepterCodeGoogle,
          text: "Intégration de Google Analytics pour optimiser le référencement SEO du site",
        },
        {
          image: WebconcepterCodeApi,
          text: "Utilisation d'une API pour l'envoie du mail",
        },
        {
          image: WebconcepterCodeEnvoie,
          text: "Extrait du code de traitement de l'envoie du mail, avec vérifications de sécurité",
        },
      ],

      details: (
        <div>
          <p className="text-2xl font-bold"> Contexte </p>
          <p>
            Dans le cadre de ma première année de BTS SIO j'ai effectué un stage
            en entreprise d'une durée de 5 semaines chez Webconcepter.<br></br>
            Au cours de ce stage j'ai eu pour mission de développer un
            formulaire de devis client étape par étape de 0. <br></br>Le
            formulaire a été spécialement conçu de sorte à offrir une expérience
            utilisateur simple et intuitive. Une fois le formulaire soumis,
            Webconcepter reçoit directement la proposition de devis des clients.
            <br></br>
            De plus, au corus de mon stage j'ai également pu faire du
            référencement SEO naturel à l'aide de backlinks, maillage interne,
            mots clés, UX, intégration Google Analytics
          </p>

          <p className="text-2xl font-bold"> Technos utilisées </p>
          <ul className="list-disc ml-8">
            <li>NextJS</li>
            <li>JavaScript</li>
            <li>TypeScript</li>
            <li>Bootstrap</li>
          </ul>

          <p className="text-2xl font-bold"> Compétences mises en oeuvre </p>
          <ul className="list-disc ml-8">
            <li>
              Gérer le patrimoine informatique{" "}
              <ul className="list-disc ml-8">
                <li>
                  Respect des normes et standards adoptés par le prestataire
                  informatique en travaillant avec un environnement de
                  développement mis en place par ce dernier.
                </li>
                <li>
                  {" "}
                  Gestion des sauvegardes avec l'utilisation de Github pour le
                  versionning de l'application.
                </li>
              </ul>
            </li>

            <li>
              Développer la présence en ligne de l’organisation
              <ul className="list-disc ml-8">
                <li>
                  Référencement SEO du site internet afin de booster la
                  visibilité du site de l'entreprise
                </li>
                <li>
                  Création d'un formulaire de devis personnalisé permettant à
                  l'entreprise de reçevoir directement des offres des clients{" "}
                </li>
              </ul>
            </li>
            <li>
              Mettre à disposition des utilisateurs un service informatique
              <ul className="list-disc ml-8">
                <li>
                  {" "}
                  Réalisations de tests d'de sécurité afin d'assurer un bon
                  fonctionnement et suivi de l'application
                </li>
              </ul>
              <ul className="list-disc ml-8">
                <li>
                  Organiser son développement professionnel
                  <ul className="list-disc ml-8"></ul>
                  <li>
                    Auto formation sur des nouvelles technologies jamais
                    utilisées aupparavant.
                  </li>
                </li>
              </ul>
            </li>
          </ul>
          <p></p>
        </div>
      ),
    },

    {
      id: 7,
      src: muslimStory,
      title: "Stage : Développement application mobile ",
      images: [
        {
          image: muslimStoryMain,
          text: "Page d'accueil",
        },
        {
          image: muslimStoryDeploy,
          text: "Déploiement de l'application sur les différents magasins d'applications, Apple/Play Store",
        },
      ],

      details: (
        <div>
          <p className="text-2xl font-bold"> Contexte </p>
          <p>
            Dans le cadre de ma seconde année de BTS SIO j'ai effectué un stage
            en entreprise d'une durée de 7 semaines chez Webconcepter.<br></br>
            Au cours de ce stage j'ai eu divers missions dont celle de finaliser
            une application mobile qui devait sortir incessamment sous peu sur
            les stores publiques
          </p>

          <p className="text-2xl font-bold"> Technos utilisées </p>
          <ul className="list-disc ml-8">
            <li>Flutter</li>
            <li>Dart</li>
          </ul>

          <p className="text-2xl font-bold"> Compétences mises en oeuvre </p>
          <ul className="list-disc ml-8">
            <li>
              Gérer le patrimoine informatique{" "}
              <ul className="list-disc ml-8">
                <li>
                  Respect des normes et standards adoptés par le prestataire
                  informatique en travaillant avec un environnement de
                  développement mis en place par ce dernier.
                </li>
                <li>
                  {" "}
                  Gestion des sauvegardes avec l'utilisation de Framagit pour le
                  versionning de l'application.
                </li>
              </ul>
            </li>
            <li>
              Développer la présence en ligne de l’organisation
              <ul className="list-disc ml-8">
                <li>
                  Déploiement de l'application sur les magasins d'applications
                  publiques.
                </li>
              </ul>
            </li>{" "}
            <li>
              Répondre aux incidents et aux demandes d’assistance et d’évolution
              <ul className="list-disc ml-8">
                <li>
                  {" "}
                  Correction de bugs signalés par les différents utilisateurs.
                </li>
              </ul>
            </li>
          </ul>
          <p></p>
        </div>
      ),
    },
    {
      id: 8,
      src: muslimStorySiteMain,
      title:
        "Stage : Développement d'un site promotionnel sortie application mobile ",
      images: [
        {
          image: muslimStorySiteMain,
          text: "Page d'accueil du site",
        },
        {
          image: muslimStorySiteMain,
          text: "Page d'accueil du site",
        },
      ],

      details: (
        <div>
          <p className="text-2xl font-bold"> Contexte </p>
          <p>
            Dans le cadre de ma seconde année de BTS SIO j'ai effectué un stage
            en entreprise d'une durée de 7 semaines chez Webconcepter.<br></br>
            Au cours de ce stage j'ai eu divers missions dont celle de faire un
            site internet promotionnel pour la sortie d'une application mobile
            Muslim Story.
            <br></br>
            Le site a par la suite été partagé afin de promouvoir l'application
          </p>

          <p className="text-2xl font-bold"> Technos utilisées </p>
          <ul className="list-disc ml-8">
            <li>Flutter</li>
            <li>Dart</li>
          </ul>

          <p className="text-2xl font-bold"> Compétences mises en oeuvre </p>
          <ul className="list-disc ml-8">
            <li>
              Gérer le patrimoine informatique{" "}
              <ul className="list-disc ml-8">
                <li>
                  Respect des normes et standards adoptés <p></p>ar le
                  prestataire informatique en travaillant avec un environnement
                  de développement mis en place par ce dernier.
                </li>
              </ul>
            </li>

            <li>
              Développer la présence en ligne de l’organisation
              <ul className="list-disc ml-8">
                <li>
                  Développement et déploiement d'un site internet promotionnel.{" "}
                </li>
              </ul>
            </li>
            <li>
              Répondre aux incidents et aux demandes d’assistance et d’évolution
              <ul className="list-disc ml-8">
                <li>
                  {" "}
                  Correction de bugs signalés par les différents utilisateurs.
                </li>
              </ul>
            </li>
          </ul>
          <p></p>
        </div>
      ),
    },
  ];

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImagePopupOpen(true);
  };

  const handleImagePopupClose = () => {
    setIsImagePopupOpen(false);
  };
  return (
    <div
      name="projets"
      className="bg-gradient-to-b sm:pb-60 from-black to-gray-800 w-full text-white md:h-fit"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            Projets
          </p>
          <p className="py-6">Découvrez certains de mes projets ici</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {projects.map(({ id, src, title, details, images }) => (
            <div
              key={id}
              className="shadow-md shadow-gray-600 rounded-lg relative"
            >
              <img
                src={src}
                alt=""
                className="rounded-md duration-200 hover:scale-105"
                onClick={() =>
                  handleProjectClick({ id, title, src, details, images })
                } // Pass details prop to handleProjectClick
              />{" "}
              <div className="flex items-center justify-center absolute bottom-0 w-full bg-black bg-opacity-50 p-4">
                <p className="w-full px-4 py-3 m-4 text-center text-white">
                  <b>{title}</b>
                </p>
                <button
                  className="px-4 py-2 bg-gradient-to-b from-purple-400 to-purple-600 text-white rounded-md"
                  onClick={() =>
                    handleProjectClick({ id, title, src, details, images })
                  } // Pass details prop to handleProjectClick
                >
                  Détails
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isPopupOpen && (
        <div className="popup fixed z-50 left-0 top-0 w-full h-full flex justify-center items-center">
          <div className="bg-gray-800 bg-opacity-75 absolute w-full h-full"></div>
          <div className="bg-black p-4 rounded-lg shadow-md relative">
            <h2 className="text-xl font-bold mb-4">{selectedProject.title}</h2>
            <p>{selectedProject.details}</p>{" "}
            <div className="w-80 h-60 mx-auto">
              {" "}
              <Slider slidesToShow={1} arrows={true}>
                {selectedProject.images.map(({ image, text }) => (
                  <div key={image} onClick={() => handleImageClick(image)}>
                    {" "}
                    <img
                      src={image}
                      alt=""
                      className="rounded-md cursor-pointer"
                    />{" "}
                    <p> {text} </p>
                  </div>
                ))}{" "}
              </Slider>
            </div>
            <button
              className="mt-4 px-4 py-2 bg-gradient-to-b from-purple-400 to-purple-600 text-white rounded-md"
              onClick={handlePopupClose}
            >
              Fermer
            </button>
          </div>{" "}
        </div>
      )}
      {isImagePopupOpen && selectedImage && (
        <div className="popup fixed z-50 left-0 top-0 w-full h-full flex justify-center items-center">
          <div className="bg-gray-800 bg-opacity-75 absolute w-full h-full"></div>
          <div className="bg-black p-4 rounded-lg shadow-md relative">
            <img src={selectedImage} alt="" className="max-w-full max-h-full" />
            <button
              className="absolute top-4 right-4 px-4 py-2 bg-gradient-to-b from-purple-400 to-purple-600 text-white rounded-md"
              onClick={handleImagePopupClose}
            >
              Fermer
            </button>
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default Portfolio;
